<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar>
        <card-change-company
          class="mx-1"
          :selectedCompany="selectedCompany"
          :isHideSome="!isOnScrollTop"
          @refreshData="initialRender"
        />
        <div class="d-flex align-center px-2 mt-4 mb-2">
          <ion-buttons slot="start">
            <ion-back-button
              v-show="false"
              color="primary"
              mode="ios"
              text=""
              default-href="/home"
              @click="goBack"
              :icon="chevronBackOutline"
            />
          </ion-buttons>
          <ion-label class="fw-600 pl-1 fs-4">{{ $t('stores') }}</ion-label>
        </div>
      </ion-toolbar>
    </ion-header>

    <ion-content :scroll-events="true" @ionScroll="handleScroll($event)">
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <skeleton-card v-if="isOpenLoading && !tenantsData.length" />

      <div v-else>
        <ion-card class="px-2 py-1" v-for="(tenant, index) in tenantsData" :key="index">
          <ion-item button lines="none" @click="setTenant(tenant.originData)">
            <ion-avatar slot="start" class="avatar-shadow">
              <img :src="tenant.logo" />
            </ion-avatar>
            <ion-label class="ion-text-wrap"> {{ tenant.name }} </ion-label>
          </ion-item>
        </ion-card>
      </div>

      <ion-loading :is-open="isRedirecting" :message="$t('please_wait')" />
    </ion-content>
  </ion-page>
</template>

<script>
import { ACTIONS } from '@/modules/b2b/store/customer/actions';
import CardChangeCompany from '@/modules/shared/components/CardChangeCompany.vue';
import { SkeletonCard } from '@/modules/shared/components/invoices-payment';
import { IMAGE_PATH } from '@/modules/shared/constants/';
import { getImage } from '@/utils/';
import { chevronBackOutline } from 'ionicons/icons';
import debounce from 'lodash.debounce';
import { defineComponent, inject, ref } from 'vue';
import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapActions } = createNamespacedHelpers('b2b/customer');

export default defineComponent({
  name: 'TenantList',
  components: {
    SkeletonCard,
    CardChangeCompany
  },
  setup() {
    const storage = inject('$storage');

    const selectedCompany = ref(null);
    const getSelectedCompany = async () => {
      selectedCompany.value = await storage.getSelectedCompany();
    };

    const isOpenLoading = ref(true);
    const setOpenLoading = (state) => (isOpenLoading.value = state);

    const isOnScrollTop = ref(false);
    const handleScroll = debounce((e) => {
      const onScrollHeightValue = e.detail.scrollTop;
      const bannerHeightVisibilityValue = 10;
      if (onScrollHeightValue > bannerHeightVisibilityValue) isOnScrollTop.value = true;
      if (onScrollHeightValue === 0) isOnScrollTop.value = false;
    }, 200);

    const goBack = () => {
      window.location.href = window.history.state?.back ?? '/';
    };

    const isRedirecting = ref(false);

    const setTenant = async (tenant) => {
      isRedirecting.value = true;

      await storage.setUserTenant(tenant);
      goBack();

      isRedirecting.value = false;
    };

    return {
      isOpenLoading,
      isOnScrollTop,
      setOpenLoading,
      chevronBackOutline,
      goBack,
      handleScroll,
      setTenant,
      isRedirecting,
      selectedCompany,
      getSelectedCompany
    };
  },
  computed: {
    ...mapGetters(['customerTenants']),

    tenantsData() {
      return this.customerTenants.map((tenant) => ({
        name: tenant.tenant.name,
        logo: getImage(tenant.logo, IMAGE_PATH.SUPPLIER_LOGO),
        originData: tenant
      }));
    }
  },
  methods: {
    ...mapActions([ACTIONS.GET_CUSTOMER_TENANTS]),

    async getTenants() {
      await this[ACTIONS.GET_CUSTOMER_TENANTS]({ customerId: this.selectedCompany.id });
    },

    async initialRender() {
      this.setOpenLoading(true);
      await this.getSelectedCompany();
      await this.getTenants();
      this.setOpenLoading(false);
    },

    async doRefresh(event) {
      await this.getTenants();
      event.target.complete();
    }
  },
  async mounted() {
    await this.initialRender();
  }
});
</script>

<style lang="scss" scoped>
ion-content {
  scroll-behavior: smooth;
}
ion-content::part(background) {
  --background: #eceff1;
}
.avatar-shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
</style>
